.flip-card {
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    transform-style: preserve-3d;
}

.flip-card-back {
    display: none; /* Hide the back side by default */
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card:hover .flip-card-front {
    display: none; /* Hide the front side on hover */
}

.flip-card:hover .flip-card-back {
    display: block; /* Show the back side on hover */
}

